import React, { useState } from 'react';
import { currencyFormater, FIVE_0F_NINETY, LEGENDARY_LOTTO, safeJSONParse } from 'utils';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';
import Modal from 'rsuite/Modal';
import Button from 'rsuite/Button';
import Tag from 'rsuite/Tag';
import styles from 'public/styles/BetHistory.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { handleSetBetSlipsFromTicket } from 'redux/actions/lotteryActions';
import RedeemTicketForm from './RedeemTicketForm';
import renderStatus from 'utils/renderStatus';
import formatGameResultColors from 'utils/formatGameResultColors';
import TicketSuccessModal from './TicketSuccessModal';

const TicketStatusModal = ({ open, handleClose, ticketStatusData }) => {
    const betSlips = safeJSONParse(ticketStatusData?.betSlips);
    const raffles = safeJSONParse(ticketStatusData?.raffle);
    const { userData, isAuthenticated } = useSelector((state) => state.userData);
    const [showRedemptionForm, setShowRedemptionForm] = useState(false);
    const [ticketSuccessValue, setTicketSuccessValue] = useState({});
    const [submittedSuccess, setSubmittedSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useDispatch();
    const handleShowRedemptionForm = () => setShowRedemptionForm((prev) => true);
    const handleCloseRedemptionForm = () => setShowRedemptionForm((prev) => false);
    const handleRebetGame = async () => {
        setIsSubmitting(true);
        const rebetRes = await dispatch(
            handleSetBetSlipsFromTicket(
                betSlips,
                ticketStatusData?.Game?.lotteryId,
                ticketStatusData?.Game?.gameId,
            ),
        );

        if (rebetRes?.success) {
            setSubmittedSuccess(true);
            setTicketSuccessValue(rebetRes?.data);
        }
        setIsSubmitting(false);
    };

    const {
        mainDraw,
        bonusDraw,
        firstDraw,
        secondDraw,
        thirdDraw,
        fourthDraw,
        fifthDraw,
        sixthDraw,
        seventhDraw,
        eightDraw,
        defaultDraw,
        mainDrawArray,
        bonusDrawArray,
        firstDrawArray,
        secondDrawArray,
        thirdDrawArray,
        fourthDrawArray,
        fifthDrawArray,
        sixthDrawArray,
        seventhDrawArray,
        eightDrawArray,
        defaultDrawArray,
    } = formatGameResultColors(ticketStatusData);
    const renderSlipStatus = (stat) => {
        if (stat === null) {
            return null;
        }
        if (stat === false) {
            return (
                <Tag color="red" size="sm">
                    {'lost'}
                </Tag>
            );
        }

        return (
            <Tag color="green" size="sm">
                {'won'}
            </Tag>
        );
    };

    return (
        <Modal
            style={{
                minHeight: '90vh',
            }}
            backdrop={'static'}
            keyboard={false}
            open={open}
            onClose={handleClose}
            size="md"
        >
            {showRedemptionForm ? (
                <RedeemTicketForm
                    ticketStatusData={ticketStatusData}
                    handleCloseRedemptionForm={handleCloseRedemptionForm}
                />
            ) : submittedSuccess ? (
                <TicketSuccessModal
                    handleClose={handleClose}
                    successValue={ticketSuccessValue}
                    open={open}
                />
            ) : (
                <>
                    <Modal.Header>
                        <Modal.Title className={styles.modalTitle}>Ticket Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {betSlips?.map((item, idx) => (
                            <div key={`${idx}cc`} className={styles.singleTicket}>
                                <p className={styles.modalSubTitle}>S/N {idx + 1}</p>
                                <p className={styles.modalSubTitle}>Bet Type: {item?.betType}</p>
                                <p className={styles.modalSubTitle}>
                                    Amount: {currencyFormater(item?.amount)}
                                </p>
                                {ticketStatusData?.Game?.Lottery?.category === FIVE_0F_NINETY ||
                                ticketStatusData?.Game?.Lottery?.category === LEGENDARY_LOTTO ? (
                                    <p className={styles.modalSubTitle}>Booster: {item?.booster}</p>
                                ) : null}
                                {ticketStatusData?.Game?.Lottery?.category === FIVE_0F_NINETY ||
                                ticketStatusData?.Game?.Lottery?.category === LEGENDARY_LOTTO ? (
                                    <p className={styles.modalSubTitle}>
                                        Result Type: {item?.resultType}
                                    </p>
                                ) : null}
                                <div className={styles.modalNumberSubTitle}>
                                    <p className={styles.modalSubTitle}>Selections: </p> &nbsp;
                                    {item?.selections?.split('-')?.map((selction, index) => (
                                        <span
                                            key={selction}
                                            className={
                                                mainDrawArray?.includes(selction)
                                                    ? 'greenText'
                                                    : bonusDrawArray?.includes(selction)
                                                    ? 'redText'
                                                    : firstDrawArray?.includes(selction)
                                                    ? 'greenText'
                                                    : secondDrawArray?.includes(selction)
                                                    ? 'redText'
                                                    : thirdDrawArray?.includes(selction)
                                                    ? 'yellowText'
                                                    : fourthDrawArray?.includes(selction)
                                                    ? 'blueText'
                                                    : fifthDrawArray?.includes(selction)
                                                    ? 'brownText'
                                                    : sixthDrawArray?.includes(selction)
                                                    ? 'purpleText'
                                                    : seventhDrawArray?.includes(selction)
                                                    ? 'orangeText'
                                                    : eightDrawArray?.includes(selction)
                                                    ? 'pinkText'
                                                    : defaultDrawArray?.includes(selction)
                                                    ? 'greenText'
                                                    : styles.number
                                            }
                                        >
                                            {selction}
                                            {index === item?.selections?.split('-')?.length - 1
                                                ? ' . '
                                                : ' ,  '}
                                        </span>
                                    ))}
                                </div>
                                {item?.overUnder?.under && (
                                    <p className={styles.modalSubTitle}>
                                        Under: {item?.overUnder?.under}
                                    </p>
                                )}
                                {item?.overUnder?.over && (
                                    <p className={styles.modalSubTitle}>
                                        Over: {item?.overUnder?.over}
                                    </p>
                                )}
                                {ticketStatusData?.resultId ? null : (
                                    <p className={styles.modalSubTitle}>
                                        Potential Winning: &#8358;
                                        {currencyFormater(Number(item?.potentialWinning))}
                                    </p>
                                )}
                                <p className={styles.modalSubTitle}>
                                    Won Amount: &#8358;{currencyFormater(Number(item?.winAmount))}
                                </p>

                                {item?.winningCombo === null ? null : (
                                    <div className={styles.modalSubTitle}>
                                        Winning Combo: &nbsp; {item?.winningCombo}
                                    </div>
                                )}
                                {item?.hasWon === null ? null : (
                                    <div className={styles.modalSubTitle}>
                                        Slip Status: &nbsp; {renderSlipStatus(item?.hasWon)}
                                    </div>
                                )}
                                {betSlips?.length > 1 && (
                                    <h6>-----------------------------------------------</h6>
                                )}
                                <br />
                            </div>
                        ))}
                        <div>
                            <p className={styles.modalSubTitle}>
                                Lines: {ticketStatusData?.linesCount}
                            </p>
                            <p className={styles.modalSubTitle}>
                                Game: {ticketStatusData?.Game?.name}
                            </p>
                            <p className={styles.modalSubTitle}>
                                Booking Code: {ticketStatusData?.bookingCode}
                            </p>
                            <p className={styles.modalSubTitle}>
                                Ticket ID: {ticketStatusData?.ticketId}
                            </p>
                            {ticketStatusData?.Gameresult?.roundNumber && (
                                <p className={styles.modalSubTitle}>
                                    Draw ID: &nbsp;{ticketStatusData?.Gameresult?.S_N}
                                </p>
                            )}
                            <NumberFormat
                                value={Number(ticketStatusData?.totalStakedAmount)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'NGN'}
                                renderText={(value, props) => (
                                    <p className={styles.modalSubTitle}>
                                        Total Staked Amount: {value}{' '}
                                    </p>
                                )}
                            />
                            <NumberFormat
                                value={Number(ticketStatusData?.totalWinAmount)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'NGN'}
                                renderText={(value, props) => (
                                    <p className={styles.modalSubTitle}>
                                        Total Win Amount: {value}{' '}
                                    </p>
                                )}
                            />
                            <p className={styles.modalSubTitle}>
                                Date:{' '}
                                {dayjs(ticketStatusData?.createdAt).format('DD/MM/YYYY, HH:mm A')}
                            </p>
                            {ticketStatusData?.Gameresult?.results ? (
                                <div className={styles.modalNumberSubTitle}>
                                    <p>
                                        Results: &nbsp;{' '}
                                        <span className={'greenText'}>{mainDraw}</span>
                                        <span className={'redText'}>{bonusDraw}</span>
                                        <span className={'greenText'}>{firstDraw}</span>
                                        <span className={'redText'}>{secondDraw}</span>
                                        <span className={'yellowText'}>{thirdDraw}</span>
                                        <span className={'blueText'}>{fourthDraw}</span>
                                        <span className={'brownText'}>{fifthDraw}</span>
                                        <span className={'purpleText'}>{sixthDraw}</span>
                                        <span className={'orangeText'}>{seventhDraw}</span>
                                        <span className={'pinkText'}>{eightDraw}</span>
                                        <span className={'greenText'}>{defaultDraw}</span>
                                    </p>
                                </div>
                            ) : null}
                            {ticketStatusData?.raffle ? (
                                <p className={styles.modalSubTitle}>
                                    Raffle:{' '}
                                    {raffles?.map((item, index) => (
                                        <span key={`${index}zx`}>
                                            {typeof item === 'object' ? (
                                                <>
                                                    {' '}
                                                    {item?.code} &#8358;
                                                    {currencyFormater(item?.winAmount)}
                                                </>
                                            ) : (
                                                <> {item} </>
                                            )}
                                            {index === raffles?.length - 1 ? ' . ' : ', '}
                                        </span>
                                    ))}
                                </p>
                            ) : null}
                            <div className={styles.modalSubTitle}>
                                Status: &nbsp; {renderStatus(ticketStatusData?.status)}
                            </div>{' '}
                        </div>

                        <div className={styles.playFormDiv}>
                            <section className={styles.buttonDiv}>
                                {submittedSuccess ? null : (
                                    <>
                                        {isAuthenticated && (
                                            <Button
                                                onClick={() => handleRebetGame()}
                                                appearance="primary"
                                                color="green"
                                                disabled={isSubmitting}
                                            >
                                                Rebet Game
                                            </Button>
                                        )}{' '}
                                        &nbsp; &nbsp;
                                        {isAuthenticated &&
                                            ticketStatusData?.winningRedemptionMethod === 'dps' &&
                                            ticketStatusData?.User?.role !== 'player' && (
                                                <>
                                                    <Button
                                                        onClick={() => handleShowRedemptionForm()}
                                                        appearance="primary"
                                                        color="yellow"
                                                        disabled={isSubmitting}
                                                    >
                                                        Redeem Winning
                                                    </Button>
                                                    &nbsp; &nbsp;
                                                </>
                                            )}
                                    </>
                                )}
                                <Button
                                    className={styles.buttonStyle}
                                    appearance="primary"
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close Preview
                                </Button>
                            </section>
                        </div>
                    </Modal.Body>
                </>
            )}
        </Modal>
    );
};

export default TicketStatusModal;
