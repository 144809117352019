import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Container, Col, Button, Content, FlexboxGrid, Panel, Form, ButtonToolbar } from 'rsuite';

import { useFormik } from 'formik';
import * as yup from 'yup';
import ErrorDiv from 'components/shared/ErrorDiv';
import { formControlStyle, getUserToken, toolBarStyle } from 'utils';

export default function RedeemTicketForm({ ticketStatusData, handleCloseRedemptionForm }) {
    const [showRedemptionForm, setShowRedemptionForm] = useState(false);

    const formik = useFormik({
        initialValues: {
            redemptionCode: '',
        },
        enableReinitialize: true,
        onSubmit: async (values, helpers) => {
            const token = getUserToken();
            let newValues = {};
            newValues.redemptionCode = values?.redemptionCode;
            newValues.ticketId = ticketStatusData?.ticketId;
            helpers.setSubmitting(true);
            return axios
                .post(
                    `${process.env.NEXT_PUBLIC_BASE_URL}/game/ticket/claim-dps-winning`,
                    newValues,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                        },
                    },
                )
                .then((res) => {
                    if (res?.data?.message && typeof res?.data?.message === 'string') {
                        toast.success(res.data?.message, { duration: 5000 });
                    } else {
                        toast.success('Ticket Redeemed Successfully', { duration: 5000 });
                    }
                    helpers.resetForm({});
                    handleCloseRedemptionForm();
                })
                .catch((err) => {
                    console.log('err', err);
                    helpers.setSubmitting(false);
                    if (err?.response?.data?.responsemessage?.msg) {
                        return toast.error(err?.response?.data?.responsemessage?.msg);
                    }
                    if (err?.response?.data?.responsemessage) {
                        return toast.error(err?.response?.data?.responsemessage);
                    }
                    if (err?.message) {
                        return toast.error(err?.message);
                    }
                });
        },
        validationSchema: yup.object().shape({
            redemptionCode: yup.string().required().label('Redemption Code'),
        }),
    });

    return (
        <>
            <Content>
                <FlexboxGrid justify="center" align="center">
                    <FlexboxGrid.Item
                        as={Col}
                        colspan={16}
                        xs={22}
                        sm={14}
                        md={16}
                        style={{
                            backgroundColor: 'white',
                            height: 'auto',
                            borderRadius: '8px',
                        }}
                        className="bordered"
                    >
                        <Panel
                            header={
                                <h4 style={{ color: 'green', textAlign: 'center' }}>
                                    Redeem Ticket
                                </h4>
                            }
                        >
                            <Form fluid onSubmit={formik.handleSubmit}>
                                <Form.Group>
                                    <Form.Control
                                        style={formControlStyle}
                                        name="redemptionCode"
                                        id="redemptionCode"
                                        onBlur={formik.handleBlur}
                                        onChange={(e) => {
                                            formik.setFieldValue('redemptionCode', e);
                                        }}
                                        placeholder="Enter Redemption Code"
                                    />
                                    {formik.errors.redemptionCode &&
                                    formik.touched.redemptionCode ? (
                                        <ErrorDiv msg={formik.errors.redemptionCode} />
                                    ) : null}
                                </Form.Group>

                                <Form.Group>
                                    <ButtonToolbar style={toolBarStyle}>
                                        <Button
                                            appearance="primary"
                                            style={{
                                                textAlign: 'center',
                                                width: '150px',
                                                borderRadius: '20px',
                                                margin: '5px auto',
                                            }}
                                            color="green"
                                            type="submit"
                                            disabled={formik.isSubmitting}
                                            // onClick={handleSubmit}
                                        >
                                            {formik.isSubmitting ? 'Processing...' : 'Continue'}
                                        </Button>
                                    </ButtonToolbar>
                                    <br />
                                    <ButtonToolbar style={toolBarStyle}>
                                        <Button
                                            appearance="ghost"
                                            style={{
                                                textAlign: 'center',
                                                width: '150px',
                                                borderRadius: '20px',
                                                margin: '5px auto',
                                            }}
                                            color="red"
                                            type="button"
                                            disabled={formik.isSubmitting}
                                            onClick={handleCloseRedemptionForm}
                                        >
                                            Cancel
                                        </Button>
                                    </ButtonToolbar>
                                </Form.Group>
                            </Form>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </>
    );
}
