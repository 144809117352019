import React, { useState } from 'react';
import dayjs from 'dayjs';
import styles from './DrawsBox.module.css';
import useSWR from 'swr';
import { formatGameDate, getUserToken } from 'utils';
import axios from 'axios';
import { Placeholder } from 'rsuite';

const DrawsBox = ({ handleChangeRouteByGame }) => {
    const today = dayjs().day();
    const [selected, setSelected] = useState(today);
    const [selectedIndex, setSelectedIndex] = useState(0);
    let days = [];
    let element = dayjs();

    for (let index = 1; index <= 7; index++) {
        days.push(element);
        element = dayjs().add(index, 'day');
    }

    const handleClick = (day, idx) => {
        const newDay = day?.day();
        setSelected((curr) => newDay);
        setSelectedIndex((curr) => newDay);
    };

    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-games?currentWeekDay=${selected}`;

    const fetcher = async () => {
        const token = getUserToken();
        const res = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
            },
        });

        return res.data?.data?.data;
    };

    const DaysContainer = () => (
        <section className={styles.daysCont}>
            {days?.map((item, idx) => (
                <div
                    key={idx}
                    className={
                        selected === item?.day() ? styles.daysInnerContActive : styles.daysInnerCont
                    }
                    onClick={() => handleClick(item)}
                >
                    <div className={styles.day}>{item?.format('ddd')}</div>
                    <div className={styles.date}>{item?.date()}</div>
                </div>
            ))}
        </section>
    );
    const { data, error } = useSWR(url, fetcher, { refreshInterval: 35000 });

    if (error) {
        console.log(error);
        return null;
    }

    if (!data) {
        return (
            <>
                <div className={styles.drawsContainer}>
                    <DaysContainer />
                    <Placeholder.Paragraph active />
                    <Placeholder.Paragraph active />
                </div>
            </>
        );
    }

    return (
        <div className={styles.drawsContainer}>
            <DaysContainer />
            <table className={styles.gamesTable}>
                <tbody className={styles.tbody}>
                    {data?.map((item, idx) => (
                        <tr
                            style={{
                                cursor: dayjs().day() === item?.weekday ? 'pointer' : 'default',
                                pointerEvents: dayjs().day() === item?.weekday ? 'auto' : 'none',
                            }}
                            key={item?.gameId}
                            className={styles.tbodyRow}
                            onClick={(e) => handleChangeRouteByGame(item, item?.Lottery)}
                        >
                            <td className={styles.tableCell}>{item?.name}</td>
                            <td className={styles.tableCell}>
                                {formatGameDate(item?.endTime, true)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DrawsBox;
