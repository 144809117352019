import React from 'react';
import Placeholder from 'rsuite/Placeholder';

const TableLoader = () => {
    const { Paragraph } = Placeholder;
    return (
        <div>
            {/* {[...new Array(7)].map((item, index) => ( */}
            <Placeholder.Grid rows={5} columns={6} active />
            {/* ))} */}
        </div>
    );
};

export default TableLoader;
