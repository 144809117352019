import {
    AFRIKENO,
    BINGO_BALLS,
    CASHOUT,
    EAZY_WIN,
    FIVE_0F_NINETY,
    GHANA_GAME,
    INDOOR_GAME,
    KENO,
    LEGENDARY_LOTTO,
    LOTTO,
    LOTTO_CONTINENTAL,
    MARIO_KENO,
    MEGA_7,
    META_4,
    METRO,
    PERFECT_BALLS,
    POOL_OF_FAME,
    SALARY_FOR_LIFE,
    SEVEN49,
    SIX_FOUR_NINE,
} from 'utils';

const formatGameResultColors = (ticketStatusData) => {
    let formatedResult = {};
    let fullResults = [];
    let mainDraw = '';
    let bonusDraw = '';
    let firstDraw = '';
    let secondDraw = '';
    let thirdDraw = '';
    let fourthDraw = '';
    let fifthDraw = '';
    let sixthDraw = '';
    let seventhDraw = '';
    let eightDraw = '';
    let defaultDraw = '';
    let mainDrawArray = [];
    let bonusDrawArray = [];
    let firstDrawArray = [];
    let secondDrawArray = [];
    let thirdDrawArray = [];
    let fourthDrawArray = [];
    let fifthDrawArray = [];
    let sixthDrawArray = [];
    let seventhDrawArray = [];
    let eightDrawArray = [];
    let defaultDrawArray = [];

    if (ticketStatusData?.Gameresult?.results) {
        fullResults = ticketStatusData?.Gameresult?.results?.split('-');
    }

    if (fullResults?.length > 0) {
        // Formating Five of Ninety and Legendary Lotto games
        if (
            ticketStatusData?.Game?.Lottery?.category === GHANA_GAME ||
            ticketStatusData?.Game?.Lottery?.category === INDOOR_GAME ||
            ticketStatusData?.Game?.Lottery?.category === FIVE_0F_NINETY ||
            ticketStatusData?.Game?.Lottery?.category === LEGENDARY_LOTTO
        ) {
            firstDraw = fullResults
                ?.slice(0, 5)
                .map((item, idx) => `${item}${idx === 4 ? '' : '-'}`);
            firstDrawArray = fullResults?.slice(0, 5);
            secondDraw = fullResults
                ?.slice(5, 10)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 4 ? '' : '-'}`);
            secondDrawArray = fullResults?.slice(5, 10);
            thirdDraw = fullResults
                ?.slice(10, 15)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 4 ? '' : '-'}`);
            thirdDrawArray = fullResults?.slice(10, 15);
            fourthDraw = fullResults
                ?.slice(15, 20)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 4 ? '' : '-'}`);
            fourthDrawArray = fullResults?.slice(15, 20);
        }

        // Formating Afrikeno games
        if (ticketStatusData?.Game?.Lottery?.category === AFRIKENO) {
            mainDraw = fullResults
                ?.slice(0, 15)
                ?.map((item, idx) => `${item}${idx === 14 ? '' : '-'}`);
            mainDrawArray = fullResults?.slice(0, 15);
            bonusDraw = fullResults
                ?.slice(15, fullResults?.length)
                ?.map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 14 ? '' : '-'}`);
            bonusDrawArray = fullResults?.slice(15, fullResults?.length);
        }

        // Formating meta 4 games
        if (ticketStatusData?.Game?.Lottery?.category === META_4) {
            mainDraw = fullResults
                ?.slice(0, 20)
                ?.map((item, idx) => `${item}${idx === 19 ? '' : '-'}`);
            mainDrawArray = fullResults?.slice(0, 20);
            bonusDraw = fullResults
                ?.slice(20, fullResults?.length)
                ?.map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 19 ? '' : '-'}`);
            bonusDrawArray = fullResults?.slice(20, fullResults?.length);
        }

        // Formating 649 games
        if (ticketStatusData?.Game?.Lottery?.category === SIX_FOUR_NINE) {
            mainDraw = fullResults
                ?.slice(0, 6)
                ?.map((item, idx) => `${item}${idx === 5 ? '' : '-'}`);
            mainDrawArray = fullResults?.slice(0, 6);
            bonusDraw = fullResults
                ?.slice(6, fullResults?.length)
                ?.map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 3 ? '' : '-'}`);
            bonusDrawArray = fullResults?.slice(6, fullResults?.length);
        }
        // Formating 749 games
        if (ticketStatusData?.Game?.Lottery?.category === SEVEN49) {
            mainDraw = fullResults
                ?.slice(0, 7)
                ?.map((item, idx) => `${item}${idx === 6 ? '' : '-'}`);
            mainDrawArray = fullResults?.slice(0, 7);
            bonusDraw = fullResults
                ?.slice(7, fullResults?.length)
                ?.map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 4 ? '' : '-'}`);
            bonusDrawArray = fullResults?.slice(7, fullResults?.length);
        }

        // Formating keno games
        if (ticketStatusData?.Game?.Lottery?.category === KENO) {
            mainDraw = fullResults
                ?.slice(0, 20)
                ?.map((item, idx) => `${item}${idx === 19 ? '' : '-'}`);
            mainDrawArray = fullResults?.slice(0, 20);
            bonusDraw = fullResults
                ?.slice(20, fullResults?.length)
                ?.map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 4 ? '' : '-'}`);
            bonusDrawArray = fullResults?.slice(20, fullResults?.length);
        }

        // Formating mega 7 games
        if (ticketStatusData?.Game?.Lottery?.category === MEGA_7) {
            mainDraw = fullResults
                ?.slice(0, 15)
                .map((item, idx) => `${item}${idx === 14 ? '' : '-'}`);
            mainDrawArray = fullResults?.slice(0, 15);
            bonusDraw = fullResults
                ?.slice(15, fullResults?.length)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 6 ? '' : '-'}`);
            bonusDrawArray = fullResults?.slice(15, fullResults?.length);
        }
        // Formating eazy win games
        if (ticketStatusData?.Game?.Lottery?.category === EAZY_WIN) {
            mainDraw = ticketStatusData?.Gameresult?.results;
            mainDrawArray = ticketStatusData?.Gameresult?.results?.split('-');
        }

        // Formating perfect balls games
        if (ticketStatusData?.Game?.Lottery?.category === PERFECT_BALLS) {
            mainDraw = ticketStatusData?.Gameresult?.results;
            mainDrawArray = ticketStatusData?.Gameresult?.results?.split('-');
        }

        // Formating Salary For Life games
        if (ticketStatusData?.Game?.Lottery?.category === SALARY_FOR_LIFE) {
            mainDraw = fullResults
                ?.slice(0, 6)
                .map((item, idx) => `${item}${idx === 5 ? '' : '-'}`);
            bonusDraw = `-${fullResults[6]}`;
            bonusDrawArray[0] = fullResults[6];
        }

        // Formating cashout games
        if (ticketStatusData?.Game?.Lottery?.category === CASHOUT) {
            mainDraw = fullResults
                ?.slice(0, 6)
                .map((item, idx) => `${item}${idx === 5 ? '' : '-'}`);
            mainDrawArray = fullResults?.slice(0, 6);
            bonusDraw = fullResults
                ?.slice(6, fullResults?.length)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 5 ? '' : '-'}`);
            bonusDrawArray = fullResults?.slice(6, fullResults?.length);
        }

        // Formating metro games
        if (ticketStatusData?.Game?.Lottery?.category === METRO) {
            mainDraw = fullResults
                ?.slice(0, 8)
                .map((item, idx) => `${item}${idx === 7 ? '' : '-'}`);
            mainDrawArray = fullResults?.slice(0, 8);
            bonusDraw = fullResults
                ?.slice(8, fullResults?.length)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 7 ? '' : '-'}`);
            bonusDrawArray = fullResults?.slice(8, fullResults?.length);
        }

        // Formating lotto games
        if (ticketStatusData?.Game?.Lottery?.category === LOTTO) {
            mainDraw = fullResults
                ?.slice(0, 15)
                .map((item, idx) => `${item}${idx === 14 ? '' : '-'}`);
            mainDrawArray = fullResults?.slice(0, 15);
            bonusDraw = fullResults
                ?.slice(15, fullResults?.length)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 14 ? '' : '-'}`);
            bonusDrawArray = fullResults?.slice(15, fullResults?.length);
        }
        // Formating pool of fame games
        if (ticketStatusData?.Game?.Lottery?.category === POOL_OF_FAME) {
            firstDraw = fullResults
                ?.slice(0, 5)
                .map((item, idx) => `${item}${idx === 4 ? '' : '-'}`);
            firstDrawArray = fullResults?.slice(0, 5);
            secondDraw = fullResults
                ?.slice(5, 10)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 4 ? '' : '-'}`);
            secondDrawArray = fullResults?.slice(5, 10);
            thirdDraw = fullResults
                ?.slice(10, 15)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 4 ? '' : '-'}`);
            thirdDrawArray = fullResults?.slice(10, 15);
        }

        // Formating mario-keno games
        if (ticketStatusData?.Game?.Lottery?.category === MARIO_KENO) {
            firstDraw = fullResults
                ?.slice(0, 10)
                .map((item, idx) => `${item}${idx === 9 ? '' : '-'}`);
            firstDrawArray = fullResults?.slice(0, 10);
            secondDraw = fullResults
                ?.slice(10, 15)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 4 ? '' : '-'}`);
            secondDrawArray = fullResults?.slice(10, 15);

            thirdDraw = fullResults
                ?.slice(16, 20)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 4 ? '' : '-'}`);
            thirdDrawArray = fullResults?.slice(16, 20);

            fourthDraw = fullResults
                ?.slice(20, 30)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 9 ? '' : '-'}`);
            fourthDrawArray = fullResults?.slice(20, 30);

            fifthDraw = fullResults
                ?.slice(30, 40)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 9 ? '' : '-'}`);
            fifthDrawArray = fullResults?.slice(30, 40);

            sixthDraw = fullResults
                ?.slice(41, 50)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 9 ? '' : '-'}`);
            sixthDrawArray = fullResults?.slice(41, 50);

            seventhDraw = fullResults
                ?.slice(51, 60)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 9 ? '' : '-'}`);
            seventhDrawArray = fullResults?.slice(51, 60);

            eightDraw = fullResults
                ?.slice(60, 65)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 4 ? '' : '-'}`);
            eightDrawArray = fullResults?.slice(60, 65);
        }

        // Formating bingo balls games
        if (ticketStatusData?.Game?.Lottery?.category === BINGO_BALLS) {
            mainDraw = fullResults
                ?.slice(0, 35)
                .map((item, idx) => `${item}${idx === 34 ? '' : '-'}`);
            mainDrawArray = fullResults?.slice(0, 35);
            bonusDraw = fullResults
                ?.slice(35, 41)
                .map((item, idx) => `${idx === 0 ? '-' : ''}${item}${idx === 5 ? '' : '-'}`);
            bonusDrawArray = fullResults?.slice(35, 41);
        }
        // Formating bingo balls games
        if (ticketStatusData?.Game?.Lottery?.category === LOTTO_CONTINENTAL) {
            defaultDraw = fullResults.map(
                (item, idx) => `${item}${idx === fullResults?.length - 1 ? '' : '-'}`,
            );
            defaultDrawArray = fullResults;
        }
    }
    formatedResult.fullResults = fullResults;
    formatedResult.mainDraw = mainDraw;
    formatedResult.bonusDraw = bonusDraw;
    formatedResult.firstDraw = firstDraw;
    formatedResult.secondDraw = secondDraw;
    formatedResult.thirdDraw = thirdDraw;
    formatedResult.fourthDraw = fourthDraw;
    formatedResult.fifthDraw = fifthDraw;
    formatedResult.sixthDraw = sixthDraw;
    formatedResult.seventhDraw = seventhDraw;
    formatedResult.eightDraw = eightDraw;
    formatedResult.defaultDraw = defaultDraw;
    formatedResult.mainDrawArray = mainDrawArray;
    formatedResult.bonusDrawArray = bonusDrawArray;
    formatedResult.firstDrawArray = firstDrawArray;
    formatedResult.secondDrawArray = secondDrawArray;
    formatedResult.thirdDrawArray = thirdDrawArray;
    formatedResult.fourthDrawArray = fourthDrawArray;
    formatedResult.fifthDrawArray = fifthDrawArray;
    formatedResult.sixthDrawArray = sixthDrawArray;
    formatedResult.seventhDrawArray = seventhDrawArray;
    formatedResult.eightDrawArray = eightDrawArray;
    formatedResult.defaultDrawArray = defaultDrawArray;

    return formatedResult;
};

export default formatGameResultColors;
