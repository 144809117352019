import React from 'react';
import Modal from 'rsuite/Modal';
import Button from 'rsuite/Button';
import Form from 'rsuite/Form';
import Radio from 'rsuite/Radio';
import RadioGroup from 'rsuite/RadioGroup';
import SelectPicker from 'rsuite/SelectPicker';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import styles from 'public/styles/GameComponents.module.css';
import {
    checkExpiredUserToken,
    currencyFormater,
    FIVE_0F_NINETY,
    getUserToken,
    LEGENDARY_LOTTO,
    POOL_OF_FAME,
    ROLES,
    safeJSONParse,
    SALARY_FOR_LIFE,
} from 'utils';
import ErrorDiv from 'components/shared/ErrorDiv';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import TicketSuccessModal from './TicketSuccessModal';
import { SESSION_STORAGE_CATEGORY } from 'redux/actions/lotteryActions';

const PlayBookedCodeModal = ({ bookedCodeData, open, handleClose }) => {
    const { activeGameInstance } = useSelector((state) => state.lotteryData);
    const { banks } = useSelector((state) => state?.bankReducer);
    const [isTicketSubmitted, setIsTicketSubmitted] = React.useState(false);
    const [successValue, setSuccessValue] = React.useState(false);

    console.log('bbbbbbbbbbbbbbbbbb', bookedCodeData);
    let lotteryCategory = '';
    if (typeof window === 'object') {
        lotteryCategory = sessionStorage.getItem(SESSION_STORAGE_CATEGORY);
    }

    const { userData, walletInformation } = useSelector((state) => state?.userData);

    const sourceWalletOptions = [
        { value: 'mainWallet', label: 'Main-Wallet' },
        { value: 'bonusWallet', label: 'Bonus Wallet' },
        { value: 'commissionWallet', label: 'Commission Wallet' },
    ];

    const betSlips = safeJSONParse(bookedCodeData?.betSlips);
    const formik = useFormik({
        initialValues: {
            winningRedemptionMethod: '',
            sourceWallet: '',
            bankCode: '',
            accountNumber: '',
            accountName: '',
        },

        onSubmit: async (values, helpers) => {
            try {
                checkExpiredUserToken();
                const token = getUserToken();

                let potentialPayload = {};
                potentialPayload.lotteryId = bookedCodeData?.Game?.lotteryId;
                potentialPayload.betSlips = bookedCodeData?.betSlips;
                const potentialResult = await axios.post(
                    `${process.env.NEXT_PUBLIC_BASE_URL}/game/ticket/get-potential-winning`,
                    potentialPayload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                        },
                    },
                );
                // console.log('potentialdatadsadaafa', potentialResult?.data?.data?.data);

                let payload = {};

                payload.bookingCode = bookedCodeData?.bookingCode;
                payload.betSlips = potentialResult?.data?.data?.data?.betSlips;
                payload.linesCount = potentialResult?.data?.data?.data?.linesCount;
                payload.totalStakedAmount = potentialResult?.data?.data?.data?.totalStakedAmount;
                payload.sourceWallet = values?.sourceWallet;
                payload.winningRedemptionMethod = values?.winningRedemptionMethod;
                payload.gameId = bookedCodeData?.Game?.gameId;
                let details = {};
                if (values?.winningRedemptionMethod === 'bank') {
                    (details.bankCode = values?.bankCode),
                        (details.accountNumber = values?.accountNumber),
                        (details.accountName = values?.accountName);
                }

                if (values?.winningRedemptionMethod === 'bank') {
                    payload.details = JSON.stringify(details);
                }
                const ticketResult = await axios.post(
                    `${process.env.NEXT_PUBLIC_BASE_URL}/game/create-ticket`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                        },
                    },
                );
                setSuccessValue(ticketResult?.data?.data?.data);
                setIsTicketSubmitted(true);
                toast.success('Ticket Created Successfully');
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (error) {
                // console.log('eeeeeeeeeeee', error?.response);
                helpers.setSubmitting(false);
                if (error?.response?.data?.responsemessage?.msg) {
                    return toast.error(error?.response?.data?.responsemessage?.msg);
                }
                if (error?.response?.data?.responsemessage) {
                    return toast.error(error?.response?.data?.responsemessage);
                }
                toast.error(error.message);
            }
        },

        validationSchema: yup.object().shape({
            winningRedemptionMethod: yup.string().required().label('Winning Redemption Method'),
            accountNumber: yup.string().when('winningRedemptionMethod', {
                is: 'bank',
                then: yup.string().label('Enter an account number'),
            }),
            bankCode: yup.string().when('winningRedemptionMethod', {
                is: 'bank',
                then: yup.string().label('Enter an bank code'),
            }),
            accountName: yup.string().when('winningRedemptionMethod', {
                is: 'bank',
                then: yup.string().label('Enter account name'),
            }),
        }),
    });

    const banksOptions = banks?.map((bank) => ({
        label: bank?.name,
        value: bank?.code,
    }));

    const handleCloseModal = () => {
        handleClose();
        window.location.reload();
    };
    return (
        <Modal
            style={{
                minHeight: '90vh',
            }}
            backdrop={'static'}
            keyboard={false}
            open={open}
            onClose={handleCloseModal}
            size="md"
        >
            {!isTicketSubmitted ? (
                <>
                    <Modal.Header>
                        <Modal.Title className={styles.modalTitle}>
                            Confirm To Play Game
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <section className={styles.betSlipsTableSection}>
                            <section>
                                {betSlips?.map((item, idx) => (
                                    <div key={`${idx}qq`} className={styles.betSlipsTableBodyRow}>
                                        <h6 className={styles.tableBodyCell}>S/N: {idx + 1}</h6>
                                        <h6 className={styles.tableBodyCell}>
                                            Selections:
                                            {item?.selections ? `${item?.selections}` : null}
                                        </h6>
                                        <h6 className={styles.tableBodyCell}>
                                            Bet Type: {item?.betType ? `${item?.betType}` : 'N/A'}
                                        </h6>

                                        {lotteryCategory === POOL_OF_FAME && (
                                            <h6 className={styles.tableBodyCell}>
                                                {item?.raffle
                                                    ? `Raffle Code: ${item?.raffle?.code}`
                                                    : null}
                                            </h6>
                                        )}
                                        {lotteryCategory === FIVE_0F_NINETY ||
                                            (lotteryCategory === LEGENDARY_LOTTO && (
                                                <h6 className={styles.tableBodyCell}>
                                                    Result Type:
                                                    {item?.resultType
                                                        ? `${item?.resultType}`
                                                        : 'Winning'}
                                                </h6>
                                            ))}
                                        <h6 className={styles.tableBodyCell}>
                                            Game Name: {activeGameInstance?.name}
                                        </h6>

                                        {lotteryCategory === FIVE_0F_NINETY ||
                                            (lotteryCategory === LEGENDARY_LOTTO && (
                                                <h6 className={styles.tableBodyCell}>
                                                    Lines: {bookedCodeData?.linesCount}
                                                </h6>
                                            ))}
                                        <NumberFormat
                                            value={Number(item?.amount)}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            className={styles.tableBodyCell}
                                            prefix={'NGN'}
                                            renderText={(value, props) => (
                                                <h6 {...props}>Staked Amount: {value}</h6>
                                            )}
                                        />
                                        {lotteryCategory === SALARY_FOR_LIFE &&
                                        item?.betType === 'match-6' ? (
                                            <h6 className={styles.tableBodyCell}>
                                                Pot. Winning:&nbsp;
                                                {item?.potentialWinning}
                                            </h6>
                                        ) : typeof item?.potentialWinning === 'string' ? (
                                            <h6 className={styles.tableBodyCell}>
                                                Pot. Winning:&nbsp; &#8358;
                                                {currencyFormater(item?.potentialWinning)}
                                            </h6>
                                        ) : (
                                            <h6 className={styles.tableBodyCell}>
                                                Pot. Winning: &nbsp;
                                                {currencyFormater(item?.potentialWinning?.min, 2)}
                                                &#8358; -{' '}
                                                {currencyFormater(item?.potentialWinning?.max, 2)}
                                                &#8358;
                                            </h6>
                                        )}
                                        {lotteryCategory === FIVE_0F_NINETY ||
                                            (lotteryCategory === LEGENDARY_LOTTO && (
                                                <h6 className={styles.tableBodyCell}>
                                                    Booster:
                                                    {item?.booster ? `${item?.booster}` : 'Default'}
                                                </h6>
                                            ))}
                                        <h6 className={styles.tableBodyCell}>
                                            {item?.overUnder?.over
                                                ? `Over ${item?.overUnder?.over}`
                                                : item?.overUnder?.under
                                                ? `Under ${item?.overUnder?.under}`
                                                : null}
                                        </h6>
                                    </div>
                                ))}
                            </section>
                        </section>
                        <div className={styles.playFormDiv}>
                            <Form fluid autoComplete="off" onSubmit={formik.handleSubmit}>
                                <input
                                    autoComplete="false"
                                    name="hidden"
                                    type="text"
                                    style={{ display: 'none' }}
                                />

                                <Form.Group controlId="sourceWallet">
                                    <Form.ControlLabel>Select Source Wallet</Form.ControlLabel>
                                    <RadioGroup
                                        style={{ padding: '0px', background: 'none' }}
                                        name="sourceWallet"
                                        onChange={(e) => formik.setFieldValue('sourceWallet', e)}
                                        inline
                                    >
                                        {sourceWalletOptions?.map((item) => (
                                            <Radio
                                                key={item?.value}
                                                title={item?.value}
                                                value={item?.value}
                                                style={{ marginLeft: '0px' }}
                                            >
                                                {item?.label}
                                            </Radio>
                                        ))}
                                    </RadioGroup>
                                </Form.Group>

                                <Form.Group controlId="winningRedemptionMethod">
                                    <Form.ControlLabel>Winning Redemption Method</Form.ControlLabel>
                                    <RadioGroup
                                        color="green"
                                        style={{ padding: '0px', background: 'none' }}
                                        name="winningRedemptionMethod"
                                        inline
                                        onChange={(e) =>
                                            formik.setFieldValue('winningRedemptionMethod', e)
                                        }
                                    >
                                        <Radio value="bank" style={{ marginLeft: '0px' }}>
                                            Bank
                                        </Radio>
                                        {userData?.role === 'agent' && (
                                            <Radio value="dps" style={{ marginLeft: '0px' }}>
                                                Decentralized Payment System (DPS)
                                            </Radio>
                                        )}
                                        {(userData?.role === ROLES.PLAYER ||
                                            userData?.role === ROLES.INFLUENCER) && (
                                            <Radio value="wallet" style={{ marginLeft: '0px' }}>
                                                Wallet
                                            </Radio>
                                        )}
                                    </RadioGroup>
                                </Form.Group>
                                {formik?.values?.winningRedemptionMethod === 'bank' &&
                                    userData?.isAgent && (
                                        <div>
                                            <Form.Group>
                                                <Form.ControlLabel></Form.ControlLabel>
                                                <SelectPicker
                                                    // searchable={false}
                                                    value={formik?.values?.bankCode || ''}
                                                    onChange={(e) =>
                                                        formik.setFieldValue('bankCode', e)
                                                    }
                                                    size="lg"
                                                    name="bankCode"
                                                    placeholder="Select Bank"
                                                    data={banksOptions}
                                                    style={{ width: '100%' }}
                                                />

                                                {formik.values?.winningRedemptionMethod ===
                                                    'bank' &&
                                                formik.touched.bankCode &&
                                                formik.errors.bankCode ? (
                                                    <ErrorDiv msg="Bank Code is Requried" />
                                                ) : null}
                                            </Form.Group>

                                            <Form.Group controlId="accountNumber">
                                                <Form.ControlLabel>
                                                    Account Number
                                                </Form.ControlLabel>
                                                <Form.Control
                                                    autoComplete="off"
                                                    name="accountNumber"
                                                    type="text"
                                                    onBlur={formik.handleBlur}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('accountNumber', e);
                                                    }}
                                                />
                                                {formik.values?.winningRedemptionMethod ===
                                                    'bank' &&
                                                formik.touched.accountNumber &&
                                                formik.errors.accountNumber ? (
                                                    <ErrorDiv msg="Account Number is Requried" />
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group controlId="accountName">
                                                <Form.ControlLabel>Account Name</Form.ControlLabel>
                                                <Form.Control
                                                    autoComplete="off"
                                                    name="accountName"
                                                    type="text"
                                                    onBlur={formik.handleBlur}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('accountName', e);
                                                    }}
                                                />
                                                {formik.values?.winningRedemptionMethod ===
                                                    'bank' &&
                                                formik.touched.accountName &&
                                                formik.errors.accountName ? (
                                                    <ErrorDiv msg="Account Name is Requried" />
                                                ) : null}
                                            </Form.Group>
                                        </div>
                                    )}
                                {formik?.values?.winningRedemptionMethod === 'bank' &&
                                    !userData?.isAgent && (
                                        <div>
                                            {walletInformation?.accountNumber ? (
                                                <p>
                                                    Your bank details: {walletInformation?.bankName}
                                                    , {walletInformation?.accountName},{' '}
                                                    {walletInformation?.accountNumber}{' '}
                                                </p>
                                            ) : (
                                                <p>
                                                    Your account is not up to date, add your account
                                                    details to use the bank redemption method or use
                                                    wallet option
                                                </p>
                                            )}
                                        </div>
                                    )}
                                <br />
                                <Form.Group className={styles.buttonDiv}>
                                    <Button
                                        className={styles.buttonStyle}
                                        disabled={formik.isSubmitting}
                                        appearance="primary"
                                        color="green"
                                        type="submit"
                                    >
                                        {formik.isSubmitting ? 'Processing' : 'Continue'}
                                    </Button>

                                    <Button
                                        className={styles.buttonStyle}
                                        disabled={formik.isSubmitting}
                                        appearance="default"
                                        type="button"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Form.Group>
                            </Form>
                        </div>
                    </Modal.Body>
                </>
            ) : (
                <TicketSuccessModal successValue={successValue} handleClose={handleClose} />
            )}
        </Modal>
    );
};

export default PlayBookedCodeModal;
