import React, { useState } from 'react';
import Container from 'rsuite/Container';

function FullScreenError({
    mainTitle = 'Oops, An Unexpected Error Occurred',
    title,
    subtitle,
    showMainTitle = true,
    color = 'white',
}) {
    return (
        <Container>
            <div
                style={{
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 10px',
                    color: color,
                }}
            >
                {/* <img
            src={ErrorImage}
            alt="an error occured"
            style={{ width: "250px", height: "250px", marginBottom: "10px" }}
          /> */}
                {showMainTitle && (
                    <h5 style={{ fontWeight: 'bold', textAlign: 'center', color: color }}>
                        {mainTitle}
                    </h5>
                )}
                <h6
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: color,
                        marginBottom: '10px',
                    }}
                >
                    {title}
                </h6>
                <p
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: color,
                        marginBottom: '10px',
                    }}
                >
                    {subtitle}
                </p>
            </div>
        </Container>
    );
}

export default FullScreenError;
