import React from 'react';
import { Tag } from 'rsuite';

export const renderSlipStatus = (stat) => {
    if (stat === null) {
        return null;
    }
    if (stat === false) {
        return (
            <Tag color="red" size="sm">
                {'lost'}
            </Tag>
        );
    }

    return (
        <Tag color="green" size="sm">
            {'won'}
        </Tag>
    );
};
const renderStatus = (stat) => {
    if (['lost', 'inactive', 'cancelled'].includes(stat)) {
        return (
            <Tag color="red" size="sm">
                {stat}
            </Tag>
        );
    }
    if (stat === 'ongoing') {
        return (
            <Tag color="blue" size="sm">
                {stat}
            </Tag>
        );
    }
    if (stat === 'held') {
        return (
            <Tag style={{ color: 'white', background: 'black' }} size="sm">
                {stat}
            </Tag>
        );
    }
    if (stat === 'canceled') {
        return (
            <Tag style={{ color: 'white', background: 'black' }} size="sm">
                {stat}
            </Tag>
        );
    }
    if (stat === 'pending') {
        return (
            <Tag color="yellow" size="sm">
                {stat}
            </Tag>
        );
    }
    return (
        <Tag color="green" size="sm">
            {stat}
        </Tag>
    );
};

export default renderStatus;
