import React from 'react';

const ErrorDiv = ({ msg }) => {
    const styles = {
        color: 'red',
        fontSize: '12px',
        paddingLeft: '4px',
        marginBottom: '4px',
        textAlign: 'left',
    };
    return <div style={styles}>{msg}</div>;
};

export default ErrorDiv;
